<template>
  <v-container class="pa-0">
    <v-tabs
      class="mb-6"
      active-class="overtext--text"
      background-color="transparent"
      slider-color="primary"
      show-arrows
    >
      <v-tab v-for="(item, i) in tabs" :key="i" :to="item.path">
        {{ item.label }}
      </v-tab>
    </v-tabs>

    <router-view @update="handleUpdate" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { loadSession } from "@/services/session";

export default {
  name: "ProfileComponent",
  data() {
    return {
      tabs: [
        {
          label: "Avatar",
          path: "/account/profile/avatar",
        },
        {
          label: "Dados Pessoais",
          path: "/account/profile/personal-data",
        },
        {
          label: "Dados Escolares",
          path: "/account/profile/school-data",
        },
        {
          label: "Segurança",
          path: "/account/profile/security",
        },
        /* {
          label: "Documentos",
          path: "/account/profile/documents",
        }, */
      ],
    };
  },
  methods: {
    ...mapActions(["setLocalUser", "setUser"]),

    handleUpdate() {
      loadSession().then((data) => {
        this.setUser(data.foreign);
        this.setLocalUser(data.local);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .v-slide-group__prev {
  min-width: 16px !important;
}

:deep() .v-slide-group__next {
  min-width: 16px !important;
}
</style>
